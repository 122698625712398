.tableTop {
    float: left;
    margin: 10px 0;
    width: 100%;



    .operationShow {
        display: inline;
    }

    .operationNone {
        display: none;
    }

    &>span {
        float: right;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: rgba(70, 74, 89, 1);
        line-height: 32px;
        margin-right: 20px;

    }

    ul {
        float: right;
        margin: 0;

        li {
            float: left;
            margin-right: 20px;

            span {
                font-size: 14px;
                font-family: PingFang-SC-Regular;
                font-weight: 400;
                // color: rgba(60, 65, 80, 1);
                line-height: 30px;
                //input {
                //    background:#0c44776b;
                //}


            }

            img {
                float: left;
            }
            .ant-calendar-range-picker-input{
                float: left;
            }
            .ant-calendar-range-picker-separator{
                float: left;
                line-height: 22px;
            }
        }
    }
    button{
        float: right;
        margin-right: 20px;
        height: 32px;

            //background: #1890ff;
            //border: none;
            //color: aliceblue;

    }
}