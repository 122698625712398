.ScreenVersionIndexV2 {
    width: 100%;
    height: 1080px;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../../img/bgV2/wznhjc0622.png) no-repeat;
    background-size: cover;
    padding: 0 44px 0 20px;
    margin: 0;

    .ScreenVersionIndexHead {
        float: left;
        width: 100%;
        margin-top: 25px;

        .ScreenVersionIndexHeadNotHaveLeftBorder {
            float: left;
            width: 1820px;

            .ScreenVersionIndexHeadTop {
                float: left;
                width: 100%;
                padding-bottom: 6px;
                border-bottom: 2px solid rgba(122, 162, 254, .2);

                .ScreenVersionIndexHeadTopLeft {
                    float: left;

                    .angle {
                        float: left;
                        transform: rotate(45deg);
                        width: 20px;
                        height: 20px;
                        background: rgba(14, 29, 62, 0);
                        border: 1px solid rgba(122, 162, 254, 1);
                        position: relative;
                        margin-right: 17px;
                        margin-left: 6px;

                        .angleRound {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 6px;
                            height: 6px;
                            background: rgba(122, 162, 254, 1);
                            border-radius: 50%;
                            margin: auto;
                        }
                    }

                    span {
                        float: left;
                        font-size: 23px;
                        font-family: MicrosoftYaHeiLight;
                        font-weight: 300;
                        color: rgba(170, 206, 255, 1);
                        line-height: 23px;
                    }
                }

                .ScreenVersionIndexHeadTopRight {
                    float: right;

                    .ScreenVersionIndexHeadTopRightTop {}

                    .ScreenVersionIndexHeadTopTime {
                        float: left;
                        font-size: 20px;
                        font-family: MicrosoftYaHeiLight;
                        font-weight: 300;
                        color: rgba(225, 237, 254, 1);
                        opacity: 0.4;
                        line-height: 22px;
                        margin-right: 24px;
                    }

                    .ScreenVersionIndexHeadTopRightDiv {
                        float: left;
                        width: 159px;
                        height: 22px;
                        background: rgba(255, 255, 255, 1);
                        opacity: 0.06;
                    }

                    .barCode {
                        float: right;
                        margin: 0;
                        margin-top: 10px;
                    }
                }

            }

            .ScreenVersionIndexHeadBottom {
                float: left;
                width: 100%;

                .ScreenVersionIndexHeadBottomLeft {
                    float: left;

                    .barCode {
                        margin-top: 10px;
                        margin-left: 18px;
                    }

                    span {
                        font-size: 10px;
                        font-family: MicrosoftYaHeiLight;
                        font-weight: 300;
                        color: rgba(255, 255, 255, 1);
                        opacity: 0.1;
                        margin-top: 6px;
                        margin-left: 6px;
                        float: left;
                    }
                }

                .ScreenVersionIndexHeadBottomRight {
                    float: right;
                    width: 131px;
                    border-top: 1px solid rgba(255, 255, 255, .1);
                    position: relative;
                    margin-top: 9px;

                    .ScreenVersionIndexHeadBottomRightRound1,
                    .ScreenVersionIndexHeadBottomRightRound2 {
                        width: 3px;
                        height: 3px;
                        background: rgba(255, 255, 255, 1);
                        opacity: 0.3;
                        border-radius: 50%;
                        position: absolute;
                        top: -2px;

                    }

                    .ScreenVersionIndexHeadBottomRightRound1 {
                        left: 0;
                    }

                    .ScreenVersionIndexHeadBottomRightRound2 {
                        right: 0;
                    }
                }
            }
        }

        .ScreenVersionIndexHeadLeftBorder {
            float: left;
            width: 4px;
            height: 29px;
            background: rgba(255, 255, 255, 1);
            opacity: 0.1;
            margin-right: 12px;
            margin-top: 30px;
        }


    }


    .ScreenVersionIndexConainer {
        width: 100%;
        float: left;
        height: 915px;
        position: relative;

        .ScreenVersionIndexConainerLeft {
            width: 1800px;
            float: left;
            margin-left: 10px;
        }

        .borderRightBorder {
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 100%;
            border: 3px solid rgba(255, 255, 255, 0.08);
            border-left: 0 none;

            .borderRightBorder2 {
                position: absolute;
                right: -3px;
                width: 3px;
                height: 27px;
                background: rgba(255, 255, 255, 1);
                opacity: 0.5;
            }

        }

        .borderRightBorder2Top {
            top: 123px;

        }

        .borderRightBorder2Bottom {
            top: 558px;

        }
    }

    .Img_ScreenVersionIndexBottom {
        float: left;
        width: 100%;
        margin-top: 15px;
        opacity: .1;
        height: 10px;
    }

    .active {
        background-color: #F5F6FA;
    }

    .hover {
        cursor: pointer;
    }
}
