.tableBottom {
    float: left;
    margin: 10px 0;
    width: 100%;
    padding-top: 13px;

    border-top: 1px solid rgba(227, 230, 237, 1);

    .reportRightTableFootLeft,
    .reportRightTableFootRight {
        float: left;

        span {
            float: left;
            line-height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(60, 65, 80, 1);
            margin-right: 20px;
        }

        div {
            float: left;
            margin-right: 20px;

            .select {
                margin: 0;

                span {
                    margin-right: 0;

                }
            }
        }
    }

    .reportRightTableFootRight {
        float: right;
    }
}