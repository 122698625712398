.indexTopRight {
    float: left;
    width: 360px;
    height: 110px;
    background: rgba(255, 255, 255, 1);
    margin: 0;
    margin-left: 20px;

    li {
        float: left;
        margin-left: 15px;
        margin-top: 18px;
        margin-right: 10px;
        position: relative;

        .indexTopRightTextImg {
            float: left;
            width: 55px;
            height: 55px;
            background: rgba(245, 246, 250, 1);
            border-radius: 50%;

            img {
                width: 70%;
                height: 70%;
                margin-top: 10px;
            }
        }

        .indexTopRightTextDiv {
            width: auto;
            height: auto;
            float: left;
            margin-top: 14px;
            margin-left: 10px;

            p {
                font-size: 14px;
                font-family: PingFang-SC-Medium;
                font-weight: 500;
                color: rgba(140, 147, 168, 1);
                line-height: 20px;
            }

            b {
                font-size: 12px;
                font-family: PingFang-SC-Bold;
                font-weight: bold;
                color: rgba(60, 65, 80, 1);
                line-height: 37px;
                width: 100%;
                float: left;
                text-align: left;

                div {

                    font-size: 26px;
                    font-family: 'PingFang-SC-Bold';
                    font-weight: bold;
                    color: #5b6b73;
                }
            }

        }

        .exception {
            p {
                margin-bottom: 5px;
            }

            .b {
                font-size: 12px;
                font-family: PingFang-SC-Bold;
                font-weight: bold;
                color: rgba(60, 65, 80, 1);
                font-weight: normal;
                width: 100%;
                margin-top: 1px;
                span{
                    float: left;
                    line-height: 24px;
                }
                div {
                    text-align: left;
                    
                    font-size: 20px;
                    font-family: 'PingFang-SC-Bold';
                    font-weight: bold;
                    color: #5b6b73;
                    line-height: 24px;
                }
            }
        }

        .border {
            float: left;
            width: 1px;
            height: 34px;
            background: rgba(235, 236, 240, 1);
            position: absolute;
            right: -35px;
            top: 15px;
        }
    }

    li:last-of-type {
        margin-right: 0;
    }
}