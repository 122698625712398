.waterBalance {
    // width: 100%;
    // height: 100%;
    // margin-top: 20px;
    float: left;
    position: absolute;
    top: 100px;
    left: 20px;
    right: 20px;
    bottom: 20px;


    .waterBalanceLeft {
        width: 245px;
        height: 100%;
        background: #fff;
        float: left;
        overflow: auto;

        .ant-tree {
            padding: 20px 10px;

            li {
                float: left;
                width: 100%;

                span {
                    float: left;
                }
            }
        }

        .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
            background-color: #6188e4;
            color: #fff;
        }
    }

    .waterBalanceRight {
        float: right;
        width: 100%;
        height: 100%;
        position: relative;

        .ant-row {
            height: 100%;

            .ant-col-8 {
                height: 100%;

                .gutter-box {
                    position: relative;
                    height: 100%;

                    img {
                        height: auto;
                        width: 60%;
                    }

                    ul {
                        float: left;
                        // width: 100%;
                        margin-top: 20px;
                        position: absolute;
                        left: 20px;
                        top: 25px;

                        li {
                            // width: 100%;
                            color: #5b6b73;
                            text-align: left;
                        }
                    }

                    .chartsContainerInWaterBalance {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: auto;
                        border: 1px solid #cccccc;
                        border-radius: 5px;
                        background-color: rgba(255, 255, 255, 0.5);

                        h4 {
                            color: #5b6b73;
                        }
                    }
                }
            }
        }

        .page2AdditionalData {
            height: 40px;
            position: absolute;
            left: 20px;
            top: 300px;
            width: 100%;
            z-index: 9;
            border-radius: 5px;
            border: 1px solid #ccc;

            li {
                float: left;
                width: 20%;
                padding:0 20px;
            
                border-right: 1px solid #ccc;

                span {
                    float: left;
                    line-height: 40px;
                }

                span:last-of-type {
                    float: right;
                }
            }

            li:last-of-type {
                border-right: 0 none;
            }
        }



    }
}