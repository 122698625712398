.water {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    position: relative;

    .waterNav {
        width: 100%;
        height: 60px;
        float: left;
        margin-top: 20px;
        background-color: #ffffff;

        ul {
            float: left;
            width: 100%;
            margin: 24px 0 0;

            li {
                float: left;
                margin-left: 20px;

                a {
                    border-bottom: 2px solid transparent;
                    width: 100px;
                    float: left;

                    span {
                        float: left;
                        font-size: 14px;
                        font-family: PingFang-SC-Medium;
                        font-weight: 500;
                        color: #8b93a7;
                        line-height: 34px;
                        width: 100%;

                    }
                }

                .active {
                    border-color: #6188E4;

                    span {
                        color: #6188E4;
                    }
                }

            }
        }
    }
}