.analysis_right_top {
    width: 100%;
    height: 355px;
    background: #ffffff;
    position: relative;
    .spinner {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: visible;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
