.indexBottom2 {
    position: relative;

    #ReactEcharts {
        margin-top: 25px;
        float: left;
    }

    .legend {
        position: absolute;
        right: 0;
        top: 75px;
        height: 75px;
        bottom: 0;
        margin: auto;

        p {
            float: left;
            font-size: 26px;
            font-family: DINPro-Medium;
            font-weight: 500;
            color: #5b6b73;
        }

        ul {
            float: left;
            width: 175px;
            li {
                float: left;
                width: 100%;
                margin-bottom: 10px;
                i {
                    width: 12px;
                    height: 12px;
                    float: left;
                    border-radius: 50%;
                }

                span {
                    float: left;
                    font-size: 12px;
                    font-family: PingFangSC-Thin;
                    font-weight: 100;
                    color: #5b6b73;
                    line-height: 15px;
                    margin-left: 10px;
                }
            }
        }
    }

    .indexBottom2Bottom {
        position: absolute;
        left: 10px;
        bottom: 5px;
        margin: auto;
        width: 100%;

        div {
            font-size: 12px;
            font-family: 'PingFang-SC-Medium';
            font-weight: 500;
            color: #8c93a8;
            line-height: 20px;
            text-align: left;
        }

    }
}