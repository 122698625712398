.reportRightTableTabAvg {
    float: left;
    width: 100%;
    height: 710px;

thead[class*="ant-table-thead"] th {
    background-color: #f6f7fb;
}

.ant-table-row:nth-child(even) {
    background-color: #f6f7fb;
}

.ant-table-tbody > tr > td {
    //padding: 7px 0px;
}
.ant-table-thead > tr > th{
    //padding: 16px 0px;
}
}