.modalEcharts {
    .colorY {
        color: #FAAD14
    }

    .colorG {
        color: #33CF86
    }

}

.modalEchartsModal {

    // float: left;
    .ant-modal-body {
        float: left;
        // min-height: 300px;
        margin-bottom: 20px;
        background-color: #fff;
        width: 100%;

        .modalEchartsModalHead {
            float: right;
            .ant-radio-group {
                margin-left: 20px;
            }
        }

        .echarts-for-react {
            display: table;
            width: 100%;
        }
    }

    ul {
        float: right;
        margin-right: 40px;

        // clear:both;
        li {
            float: left;
        }
    }
}