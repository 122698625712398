.ScreenVersionIndexV3 {
    .indexBottomV3 {
        .indexBottom1V3 {
            width: 500px;
            margin-left: 30px;
            margin-top: 15px;
            ul {
                margin-top: 25px;
                float: left;
                width: 100%;
                height: 240px;

                li {
                    width: 50%;
                    height: 50%;
                    float: left;
                    position: relative;

                    .echarts-for-react {
                        float: left;
                        width: 100%;
                        height: 100%;
                        margin-top: 0;
                    }

                    p {
                        // float: left;
                        width: 100%;
                        height: 50px;
                        text-align: center;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        color: #fff;
                    }
                }
            }
        }
    }
}