.ScreenVersionIndexV3 {

    .indexBottomV3 {
        // width: 100%;
        //margin-top: 20px;
        margin-top: 60px;
        margin-left: 0;
        margin-bottom: 20px;
        float: left;


        .indexBottom1,
        .indexBottom2,
        .indexBottom3,
        .indexBottom4 {
            width: 420px;
            height: 345px;
            background: transparent;
            padding: 15px;
            float: left;

            .indexBottomHead {
                float: left;
                width: 100%;
                position: relative;
                z-index: 999;

                .border4Container {
                    float: left;
                    width: 17px;
                    height: 17px;
                    position: relative;

                    .round {
                        float: left;
                        width: 5px;
                        height: 5px;
                        background: rgba(254, 181, 47, 1);
                        opacity: 0.8;
                        border-radius: 50%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        padding: 0;
                    }
                }

                span {
                    float: left;
                    font-size: 14px;
                    font-family: PingFang-SC-Bold;
                    font-weight: bold;
                    color: rgba(140, 147, 168, 1);
                    line-height: 17px;
                    margin-left: 5px;
                }

                div {
                    float: right;

                    i {
                        float: left;
                        border-radius: 0px;
                        width: 26px;
                        height: 26px;
                        background: rgba(14, 29, 62, 1);
                        border: 0 none;
                        opacity: 0.3;
                        margin-left: 5px;
                        text-align: center;
                        line-height: 26px;
                        padding: 0;

                        img {
                            width: 26px;
                            height: 26px;
                        }
                    }

                    .active {
                        background: rgba(255, 255, 255, 1);
                        opacity: 0.2;
                    }
                }

            }


            .echarts-for-react {
                float: left;
                margin-top: 25px;
            }
        }

        .indexBottom2 {
            .echarts-for-react {
                float: left;
                margin-top: 0px;
            }
        }

    }
    .mr10{
        margin-right: 10px;
    }
}