.reportNoise {
    height: 100%;
    width: 100%;
    background: url(../../img/bgV2/device0721.png);
    //margin-top: 20px;


    .reporLeft {
        width: 280px;
        height: 100%;
        background: #ffffff00;
        float: left;
        overflow: auto;

        .reporLeftTit {
            width: 100%;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
            //color: #8b93a7;
            color: #a79d48;
            line-height: 34px;
            border-bottom: 1px solid #e8e8e8;
            margin-top: 15px;
            text-align: left;
            padding: 0 30px 10px;
            font-weight: 600;
        }
    //    修改颜色
        .ant-tree li .ant-tree-node-content-wrapper {
            color: #04ba7e;
        }


    }

    .reporRight {
        float: left;
        margin-left: 20px;
        width: 1580px;
        height: 100%;
        position: relative;

        .reportNav {
            width: 100%;
            height: 60px;
            float: left;
            background-color: #ffffff00;

            ul {
                float: left;
                /* width: 100%; */
                margin: 24px 0 0;
                position: relative;
                z-index: 9;

                li {
                    float: left;
                    margin-left: 20px;

                    a {
                        border-bottom: 2px solid transparent;
                        width: 100px;
                        float: left;

                        span {
                            float: left;
                            font-size: 14px;
                            font-family: PingFang-SC-Medium;
                            font-weight: 500;
                            color: #8b93a7;
                            line-height: 34px;
                            width: 100%;

                        }
                    }

                    .active {
                        border-color: #6188E4;

                        span {
                            color: #6188E4;
                        }
                    }

                }
            }
        }

        .reportRightTable {
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            // width: 1580px;
            // height: 685px;
            // padding: 0 20px;
            float: left;
            background: #ffffff00;
            margin-top: 20px;

            .tableTop {
                position: absolute;
                top: -75px;
                left: -25px;
            }

            .reportRightTableTab {
                float: left;
                width: 100%;
                height: 750px;

                .ant-table-header {
                    overflow: hidden;
                }

                th,
                td {
                    color: #5b6b73;
                }

                thead[class*="ant-table-thead"] th {
                    background-color: #F6F7FB;
                }

                .ant-table-thead > tr > th,
                .ant-table-tbody > tr > td {
                    padding: 10px 16px;
                }

                .tableName {
                    float: left;
                    width: 110px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .reporRightWeather {
        float: left;
        margin-left: 20px;
        width: 100%;
        height: 100%;
        position: relative;

        .reportNav {
            width: 100%;
            height: 60px;
            float: left;
            background-color: #ffffff;

            ul {
                float: left;
                /* width: 100%; */
                margin: 24px 0 0;
                position: relative;
                z-index: 9;

                li {
                    float: left;
                    margin-left: 20px;

                    a {
                        border-bottom: 2px solid transparent;
                        width: 100px;
                        float: left;

                        span {
                            float: left;
                            font-size: 14px;
                            font-family: PingFang-SC-Medium;
                            font-weight: 500;
                            color: #8b93a7;
                            line-height: 34px;
                            width: 100%;

                        }
                    }

                    .active {
                        border-color: #6188E4;

                        span {
                            color: #6188E4;
                        }
                    }

                }
            }
        }

        .reportRightTable {
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            // width: 1580px;
            // height: 685px;
            // padding: 0 20px;
            float: left;
            background: #ffffff;
            margin-top: 20px;

            .tableTop {
                position: absolute;
                top: -75px;
                left: -25px;
            }

            .reportRightTableTab {
                float: left;
                width: 100%;
                height: 750px;

                .ant-table-header {
                    overflow: hidden;
                }

                th,
                td {
                    color: #5b6b73;
                }

                thead[class*="ant-table-thead"] th {
                    background-color: #F6F7FB;
                }

                .ant-table-thead > tr > th,
                .ant-table-tbody > tr > td {
                    padding: 10px 16px;
                }

                .tableName {
                    float: left;
                    width: 110px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}