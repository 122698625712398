.border4Container {
    position: relative;
    float: left;

    .border4 {
        position: absolute;
        width: 10px;
        opacity: 0.4;
        width: 21px;
        height: 21px;
    }

    .border4LeftTop,
    .border4RightTop {
        border-top: 1px solid #7aa2fe;
        top: 0;
    }

    .border4LeftBottom,
    .border4RightBottom {
        border-bottom: 1px solid #7aa2fe;
        bottom: 0;
    }

    .border4LeftTop,
    .border4LeftBottom {
        border-left: 1px solid #7aa2fe;
        left: 0;
    }

    .border4RightTop,
    .border4RightBottom {
        border-right: 1px solid #7aa2fe;
        right: 0;
    }

}