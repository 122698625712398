.side {
    width: 280px;
    height: 750px;
    background: #ffffff;
    margin-top: 20px;
    float: left;
    overflow-y: auto;


        .equipmentLeftTit {
            width: 100%;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
            color: #8b93a7;
            line-height: 34px;
            border-bottom: 1px solid #e8e8e8;
            margin-top: 5px;
            text-align: left;
            padding: 0 30px;
            font-weight: 600;
        }

        .ant-tree {
            padding: 0px 10px;

            li {
                float: left;
                width: 100%;

                span {
                    float: left;
                }
            }
        }

        .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
            background-color: #6188e4;
            color: #fff;
    }

}