.ScreenVersionIndexV2 {
    .indexTopLeftV2 {
        width: 720px;
        height: 110px;
        background: transparent;
        /* margin-left: 690px; */
        /* margin-top: 60%; */
        left: 560px;
        //top: 500px;
        top: 797px;
        position: absolute;
        tr {
            //height: 190px;
            height: 130px;
        }

        tr td{
            //float: left;
            //// margin-left: 20px;
            //margin-top: 23px;
            //// margin-right: 10px;
            //position: relative;
            //width: 228px;
            //padding: 0 32px;
            width:3%;

            .imagIcon {
                width: 80px;
                height: 80px;
                float: left;
                margin-right: 5px;
            }
            .dollarImg {
            background: url(../../../../img/bgV2/dollar41.png) ;
                background-size: 80px 80px;
            }
            .energyImg {
            background: url(../../../../img/bgV2/energy41.png) ;
                background-size: 80px 80px;
            }
            p {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                //color: rgba(170, 206, 255, 1);
                color: #02b9fa;
                opacity: 0.5;

                line-height: 20px;
                text-align: left;

                span {
                    //float: right;
                    //float: left;
                    line-height: 22px;
                    font-size: 12px;
                    font-family: DINPro-Regular;
                    font-weight: 400;
                    color: rgba(252, 252, 252, 1);

                    i {
                        //float: right;
                        //float: left;

                        img {
                            width: 30px;
                            height: 30px;
                            margin-top: -5px;
                            margin-left: -5px;
                            transform: rotate(180deg);
                        }
                    }
                }

                .up {
                    color: #FF2944;
                }

                .down {
                    color: #33CF86;
                }
            }

            b {
                //float: left;
                width: 100%;
                height: 37px;
                line-height: 37px;
                text-align: left;

                font-size: 24px;
                font-family: DINPro-Medium;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
            }

            .border {
                float: left;
                width: 1px;
                height: 34px;
                position: absolute;
                right: 10px;
                top: 15px;
                width: 1px;

                height: 60px;
                background: rgba(255, 255, 255, 1);
                opacity: 0.08;
            }
        }

        li:last-of-type {
            padding-right: 0;
        }
    }
}