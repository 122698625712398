.ScreenVersionIndex {
    .indexMidRightContainer {
        float: left;
        width: 626px;
        margin-left: 10px;
    }

    .mb20 {
        margin-bottom: 18px;

    }

    .indexMidRight {
        height: 202px;
        background: transparent;
        float: left;
        margin-left: 20px;


        .indexMidRightHead {
            float: left;
            width: 100%;
            padding: 20px 16px 5px;

            .border4Container {
                float: left;
                width: 17px;
                height: 17px;
                position: relative;

                .round {
                    float: left;
                    width: 5px;
                    height: 5px;
                    background: rgba(254, 181, 47, 1);
                    opacity: 0.8;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    padding: 0;
                }
            }

            span {
                float: left;
                font-size: 14px;
                font-family: PingFang-SC-Bold;
                font-weight: bold;
                color: rgba(140, 147, 168, 1);
                line-height: 17px;
                margin-left: 10px;
            }

            div {
                float: right;

                i {
                    float: left;
                    width: auto;
                    height: auto;
                    border-radius: none;
                    border: 0 none;

                    img {
                        width: 32px;
                        height: 32px;
                    }
                }

                i:last-of-type {
                    border-left: none;
                }
            }
        }

        .echarts-for-react {
            float: left;
        }
    }
}