.reportRightTableWeather {
    float: left;
    width: 90%;
    height: 100%;
}

.reportRightTableTabWeather {
    float: left;
    width: 90%;
    height: 650px;

thead[class*="ant-table-thead"] th {
    //background-color: #f6f7fb;
    background-color: #c3d5e8;
}

.ant-table-row:nth-child(even) {
    background-color: #c3d5e8;
}

.ant-table-tbody > tr > td {
    //padding: 7px 0px;
    padding: 0px 0px;
    background-color: #c3d5e8;
    //width: 5%;
}

.ant-table-thead > tr > th{
    //padding: 16px 0px;
    padding: 0px 0px;
    background-color: #c3d5e8;
    //width: 5%;
}

    .ant-table-bordered .ant-table-header > table,
    .ant-table-bordered .ant-table-body > table,
    .ant-table-bordered .ant-table-fixed-left table,
    .ant-table-bordered .ant-table-fixed-right table{
        //border-left: none;
        //border-bottom: none;
        //border-right: none;
        border:solid 1px black;
        background-color: #c3d5e8;
    }

    .ant-table-bordered {
        .ant-table-thead > tr > th{
            //border-right: none;
            //border-bottom: none;
            border:solid 1px black;
            background-color: #c3d5e8;
        }
        .ant-table-tbody > tr > td{
            //border-right: none;
            //border-bottom: none;
            border:solid 1px black;
            background-color: #c3d5e8;
        }
    }

    .ant-table-tbody > tr > td{
        //border-bottom: none;
        border:solid 1px black;
        background-color: #c3d5e8;
    }

    //.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
    //    padding: 0px 0px;
    //}
}