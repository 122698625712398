.components_echarts {
    position: relative;

    .components_echarts_head {
        margin-top: 20px;
        padding: 0 20px;
        float: left;
        width: 100%;

        p {
            float: left;
            font-size: 14px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
        }

        .components_echarts_head_radio {
            float: right;
        }


    }

    .echarts-for-react {
        z-index: 100;
        float: left;
        width: 100%;
    }
}