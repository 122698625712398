.chartsContainer {
    position: absolute;
    top: 0; // left: -700px;
    bottom: 0;
    width: 700px;
    // height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    transition: all 800ms;
    padding: 0;
    margin: 0;
    // z-index: 99999;

    li {
        list-style: none;
        height: 33.333333333333333%;
        // width: 98%; // float: left;
        border-bottom: rgba(78, 69, 69, 0.83) solid 1px;
        margin: 0 auto;
        padding: 10px 0;
        color: #fff;
        position: relative;

        .liTopLeft ,
        .liTopRight {
            .ant-col-16 {
                text-align: left;
                padding-left: 50px;
                line-height: 40px;
            }

            .ant-col-8 {
                text-align: left;
                padding-right: 20px;
                line-height: 44px;
            }
        }

        .border {
            width: 1px;
            position: absolute;
            top: 20px;
            bottom: 20px;
            margin: 0 auto;
            background-color: rgba(193, 193, 193, 0.52);
        }

        .echarts-for-react {
            height: 100%;
            width: 100%;
            padding: 0 10px;
        }

        .charts50 {
            width: 50%;
            height: 100%;
            display: inline-block;

            .smallCharts {
                width: 50%;
                height: 50%;
                display: inline-block;
                // margin-top: 5px;

                div {
                    float: left;
                    width: 100%;

                    // margin-top: -40px;
                    p {
                        text-align: center;
                        float: left;
                        width: 100%;
                        margin: 0;
                        line-height: normal;
                    }
                }
            }

            .charts100 {
                width: 100%;
                float: left;
                height: 75%;
                display: inline-block;
            }

            .charts60 {
                width: 100%;
                height: 60%;
                display: inline-block;
                float: left;
            }

            div {
                width: 45%;
                float: left;

                p {
                    margin: 0;
                    text-align: inherit
                }
            }

            div:last-of-type {
                float: right;
            }
        }

        .charts100 {
            width: 100%;
            height: 90%;
            display: inline-block;
        }

        p {
            font-size: 14px;
            color: #fff;
            margin: 0;
            margin-top: 3px;
            float: left;
            width: 100%;

            span {
                float: right;
                margin-right: 5px;

                b {
                    font-weight: normal;
                    margin-left: 5px;
                    padding: 3px 5px;
                    border-radius: 5px;
                }

                b:hover {
                    cursor: pointer;
                }

                .check {
                    background-color: rgba(255, 255, 255, 0.5); // color: #fff;
                }
            }
        }
    }

    // li:nth-of-type(2){
    //     height: 60%;
    // }
    li:last-of-type {
        // height: 66.666666666666%;
        border: 0 none;
    }
}

.chartsOpen {
    left: 0px;
}

.chartsClose {
    left: -700px;
}
