.newRegisterInfo {
    background-color: #fff;
    float: left;
    width: 100%;
    height: 100%;
    .newProjectForm {
        padding: 0 10px 0 80px;
        float: left;
        width: 100%;
        height: 120%;
        margin-top: 10px;
      z-index: 888;

        .border-nice {
            padding: 24px;
            //margin: 24px;
            margin-bottom: 10px;
            margin-right: 40px;
            background: #fbfbfb;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
        }

        .border-nice2 {
            padding: 24px;
            //margin: 24px;
            margin-bottom: 10px;
            margin-right: 60px;
            background: #fbfbfb;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
        }

        .ant-row {
            float: left;
            width: 100%;
        }

        .newProjectFormLeft {
            float: left;
            width: 40%;
            border-right: 1px solid #E3E6ED;
            padding-right: 30px;
        }

        .newProjectFormRight {
            float: right;
            width: 60%;
        }

        label {
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(140, 147, 168, 1);
            // line-height: 20px;
            float: left;
        }

        h2 {
            font-size: 16px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
            text-align: left;
        }

        .steps {

          width: 120%;
        }

        .newProjectFormButton {
            width: 100px;
            height: 32px;
            // background: rgba(97, 136, 228, 1);
            border-radius: 2px;
            margin-left: 20px;
        }
    }
    .registerAdminOpen {
        padding: 0 140px 0 80px;
        float: left;
        width: 100%;
        margin-top: 50px;

        .ant-row {
            float: left;
            width: 100%;
        }

        .newProjectFormLeft {
            float: left;
            width: 40%;
            border-right: 1px solid #E3E6ED;
            padding-right: 30px;
        }

        .newProjectFormRight {
            float: right;
            width: 60%;
        }

        label {
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(140, 147, 168, 1);
            // line-height: 20px;
            float: left;
        }

        h4 {
            font-size: 14px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
            text-align: left;
        }

        .newProjectFormButton {
            width: 100px;
            height: 32px;
            // background: rgba(97, 136, 228, 1);
            border-radius: 2px;
            margin-left: 20px;
        }
    }
    .registerAdminClose {
        display: none;
        padding: 0 140px 0 80px;
        float: left;
        width: 100%;
        margin-top: 50px;

        .ant-row {
            float: left;
            width: 100%;
        }

        .newProjectFormLeft {
            float: left;
            width: 40%;
            border-right: 1px solid #E3E6ED;
            padding-right: 30px;
        }

        .newProjectFormRight {
            float: right;
            width: 60%;
        }

        label {
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(140, 147, 168, 1);
            // line-height: 20px;
            float: left;
        }

        h4 {
            font-size: 14px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
            text-align: left;
        }

        .newProjectFormButton {
            width: 100px;
            height: 32px;
            // background: rgba(97, 136, 228, 1);
            border-radius: 2px;
            margin-left: 20px;
        }
    }
    .chatForm {
        padding: 0 10px 0 80px;
        float: left;
        width: 100%;
        height: 20%;
        margin-top: 200px;
        background-color: #dde5d063;
        z-index:-999;

        .border-nice {
            padding: 24px;
            //margin: 24px;
            margin-bottom: 10px;
            margin-right: 40px;
            background: #fbfbfb;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
        }

        .border-nice2 {
            padding: 24px;
            //margin: 24px;
            margin-bottom: 10px;
            margin-right: 60px;
            background: #fbfbfb;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
        }

        .ant-row {
            float: left;
            width: 100%;
        }

        .newProjectFormLeft {
            float: left;
            width: 40%;
            border-right: 1px solid #E3E6ED;
            padding-right: 30px;
        }

        .newProjectFormRight {
            float: right;
            width: 60%;
        }

        label {
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: rgba(140, 147, 168, 1);
            // line-height: 20px;
            float: left;
        }

        h2 {
            font-size: 16px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
            text-align: left;
        }

        .newProjectFormButton {
            width: 100px;
            height: 32px;
            // background: rgba(97, 136, 228, 1);
            border-radius: 2px;
            margin-left: 20px;
        }
    }
}