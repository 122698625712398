.barCode {
    float: left;

    .barCode1,
    .barCode2,
    .barCode3,
    .barCode4 {
        height: 9px;
        background: rgba(255, 255, 255, 1);
        opacity: 0.1;
        margin-right: 2px;
        float: left;
    }

    .barCode1 {
        width: 3px;
    }

    .barCode2,
    .barCode3 {
        width: 1px;
    }

    .barCode4 {
        width: 8px;
        margin: 0;
    }
}