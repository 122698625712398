.waterMeter {
    position: absolute;
    cursor: pointer;

    img {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 5px;
    }

    p {
        float: left;
        margin-left: 5px;
        line-height: 30px;
        color: #fff;
    }


}

.waterMeterModal {
    .modalBodyHead {
        // float: left;
        height: 32px;
        width: 100%;
        margin-bottom: 10px;
        .modalBodyHeadLeft {
            float: left;
        }

        .modalBodyHeadRight {
            float: right;
            .ant-radio-group{
                margin-left: 10px;
            }
        }
    }
}