.tree_tree {
    padding: 0 20px;

    ul {
        width: 100%;
        overflow: hidden;
        float: left;

        li {
            float: left;
            width: 100%;
            text-align: left;

            .tree_tree_more {
                float: right;
                position: relative;
                display: none
            }

            img {
                width: 30px;
                height: 30px;

            }

            input {
                margin: 7px;
            }

            span {
                font-size: 14px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                color: rgba(140, 147, 168, 1);
                line-height: 20px;
            }

            img[src=""],
            img:not([src]) {
                opacity: 0;
            }

            ul {
                padding-left: 20px;
            }
        }

        li:hover .tree_tree_more {
            display: inherit;
        }
    }

    .ulOpen {
        height: auto;
        // max-height: 3000px;
    }

    .ulClose {
        height: 0;
    }

    .imgR {
        transform: rotate(0deg)
    }

    .imgD {
        transform: rotate(90deg)
    }


}
//.ant-tree-node-content-wrapper{
//    position:absolute;
//}
//.ant-tree-node-content-wrapper-normal{
//    position:absolute;
//}
//.ant-tree-node-selected{
//    position:absolute;
//}
