.networkDiagram {
    .networkDiagramImgContainer {
        width: 'calc(100%-40px)';
        height: 100%;
        margin: 20px;
        position: relative;
        float: left;

        .waterBg {
            float: left;
            width: 100%;
            height: 100%;
        }

        .page2AdditionalData {
            position: absolute;
            bottom: 0;
            right: 25px;
            // height: 120px;
            width: 1060px;
            border: 1px solid #82b7e8;
            border-radius: 10px;
            padding: 10px 0;

            div {
                border-right: 1px solid #ccc;

                span {
                    float: left;
                    line-height: 26px;
                }

                span:last-of-type {
                    float: right;
                }
            }

            div:nth-of-type(3n) {
                border-right: 0 none;
            }
        }

    }

    .ant-spin {}
}