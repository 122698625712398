.equipmentV2 {
  position: absolute;
  top: -60px;
  height: 1080px;
  //height: 100%;
  width: 100%;
  padding-top: 20px;
  background: url(../../img/bgV2/device0721.png);
  .equipmentLeft {
    width: 280px;
    height: calc(100% - 20px);
    padding-top: 60px;
    //background: #fff;
    background: #05264c00;
    //opacity: 0;
    float: left;
    overflow: auto;

    .equipmentLeftTit {
      width: 100%;
      font-size: 14px;
      font-family: PingFang-SC-Medium;
      //color: #8b93a7;
      color: #07b2b3;
      line-height: 34px;
      border-bottom: 1px solid #e8e8e8;
      margin-top: 5px;
      text-align: left;
      padding: 0 30px;
      font-weight: 600;
    }

    .ant-tree {
      padding: 0px 10px;

      li {
        float: left;
        width: 100%;

        span {
          float: left;
          color:#04ba7e;
        }
      }
    }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: #6188e4;
      //color: #fff;
      color: #04ba7e;

    }
    .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
      background-color: #0d164af7;
    }

  }

  .equipmentRight {
    float: left;
    margin-left: 20px;
    width: 1580px;
    height: 100%;
    position: relative;
    padding-top: 60px;

    .reportNav {
      width: 100%;
      height: 40px;
      float: left;
      //background-color: #ffffff;
      background-color: #ffffff00;

      ul {
        float: left;
        width: 100%;
        margin: 4px 0 0;

        li {
          float: left;
          margin-left: 20px;
          border-bottom: 2px solid transparent;

          span {
            float: left;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: #8b93a7;
            line-height: 34px;
            width: 100px;
          }
        }

        .active {
          border-color: #6188e4;

          span {
            color: #6188e4;
          }
        }
      }
    }
    .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body{
      background: #ffffff00;
      color:#459dfd;
    }
    .reportRightTable {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      bottom: 20px;
      // width: 1580px;
      // height: 750px;
      padding: 0 20px;
      float: left;
      //background: #ffffff;
      background: #ffffff00;
      margin-top: 20px;

      .reportRightTableTab {
        float: left;
        width: 100%;
        height: 710px;

        thead[class*="ant-table-thead"] th {
          //background-color: #f6f7fb;
          background-color: #f6f7fb00;
          color:#459dfd;
        }

        .ant-table-row:nth-child(even) {
          //background-color: #f6f7fb;
          background-color: #f6f7fb00;
        }

        .ant-table-tbody > tr > td {
          padding: 7px 0px;
          background-color: #f6f7fb00;
          border-bottom: 1px solid rgba(81, 84, 65, 0.45);
        }
        .ant-table-thead > tr > th{
          padding: 16px 0px;
          background-color: #f6f7fb00;
          border-bottom: 1px solid rgba(232, 232, 232, 0.49);
        }

        .ant-table-header {
          background-color: #f6f7fb00;
        }
        .ant-checkbox-inner {
          position: relative;
          top: 0;
          left: 0;
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid rgba(217, 181, 116, 0.65);
          border-radius: 2px;
          background-color: #12bfd4bf;
        }


      }

      .gear {
        float: left;

        li {
          float: left;
        }
      }

      .hidden {
        display: none;
      }

      .colorY {
        color: #faad14;
      }

      .colorG {
        color: #33cf86;
      }
    }

    .reportRightTableFoot {
      float: left;
      width: 100%;
      float: right;
      //border-top: 1px solid #e8e8e8;
      border-top: 1px solid #e8e8e854;
      padding-top: 10px;

      .pagination {
        float: right;
      }
    }
  }
}
