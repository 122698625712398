.ScreenVersionIndexV3 {
    .indexTopV3 {
        //width: 100%;
        //margin-top: 0;
        //float: left;
        position: relative;
        .ml-10px{
            margin-left: 10px;
        }
        .title{
            color: rgb(217, 221, 228);
            width: 600px;
            background-image: -webkit-linear-gradient(bottom, #59e280, #bdf5ce,#fff8e0);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 43px;
        }

    }
}