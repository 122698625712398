.indexAverage {
    width: 1900px;
    height: 100%;

    .active {
        background-color: #F5F6FA;
    }

    .hover {
        cursor: pointer;
    }
}