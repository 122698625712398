.indexMidLeft,
.indexMidRight {
    width: 1240px;
    height: 345px;
    background: rgba(255, 255, 255, 1);
    float: left;
    margin-left: 20px;


    .indexMidLeftHead,
    .indexMidRightHead {
        float: left;
        width: 100%;
        padding: 20px 16px;

        span {
            float: left;
            font-size: 14px;
            font-family: PingFang-SC-Bold;
            font-weight: bold;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
        }

        div {
            float: right;

            i {
                float: left;
                width: 32px;
                height: 32px;
                border-radius: 0px 2px 2px 0px;
                border: 1px solid rgba(227, 230, 237, 1);

                img {
                    width: 32px;
                    height: 32px;
                }
            }

            i:last-of-type {
                border-left: none;
            }
        }
    }

    .echarts-for-react {
        float: left;
    }
}