.electrical {
    width: 100%;
    height: 100%;
    float: left;

    .electricalPage {
        width: 100%;
        margin-top: 15px;

        button {
            margin: 0 5px;
        }
       

    }

    .electricalImgContainer {
        height: 800px;
        padding: 20px;
        margin-bottom: 15px;
        position: relative;



        .electricalImgCenter {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            width: 1546px;
            height:100%;
.spin{ 
    position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto;background:rgba(255,255,255,.5) ;
.ant-spin-dot.ant-spin-dot-spin{
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
margin :auto;
}
}
            .electricalImg {
                height: auto;
                width: 100%;
                float: left;
            }

            .dataContainer {
                position: absolute;

                .electricalStateImg {
                    float: left;
                    height: 30px;
                    width: auto;
                }

                ul {
                    width: 160px;
                    max-height: 40px;
                    background-color: rgba($color: #000000, $alpha: .5);
                    position: absolute;
                    overflow: hidden;
                    left: 45px;
                    top: -50px;
                    transition: max-height 300ms;
                    padding: 0 10px;

                    li {
                        height: 40px;
                        line-height: 40px;
                        color: #fff;

                        span {
                            width: 50%;
                            float: left;
                            text-align: left;
                        }

                        span:last-of-type {
                            text-align: right;
                            white-space: nowrap;
                        }
                    }

                    li:first-of-type {
                        span {
                            width: 100%;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

                ul:hover {
                    max-height: 320px;
                    cursor: pointer;
                    background-color: rgba($color: #000000, $alpha: 1);
                    z-index: 999;
                }

                .electricalPathImg {
                    float: left;
                    position: absolute;
                    left: 15px;
                    top: -30px;
                }
            }
        }


    }
}