.tableEdit {
    p {
        float: left;
        margin: 0;
        span {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            float: left;
        }
    }


    .tableHover {
        display: none;
    }

    &:hover .tableHover {
        display: inline;
    }

    .tableHover:hover {
        cursor: pointer;
    }

}