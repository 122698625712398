.ScreenVersionIndexV3 {
    .indexTopRightV3 {

        //position: absolute;
        position: relative;
        left: 960px;
        top: 900px;


        li {
            float: left;
            //margin-left: 0;
            margin-top: 8px;
            margin-right: 0;
            position: relative;
            margin-left: 92px;


            .indexTopRightTextImg {
                float: left;
                width: 93px;
                height: 93px;
                background: transparent;
                border-radius: 0;

                font-size: 50px;
                line-height: 93px;
            }

            .online {
                color: #2DFAD8;
            }

            .err {
                color: #FF3C3C;
            }

            .svg1 {

                padding: 14px 15px 10px;
            }

            .svg2 {
                width: 24px;
                height: 20px;
                top: 17px;
            }

            .svg3 {
                width: 42px;
                height: 30px;
                top: 12px;
            }

            .svg2,
            .svg3 {
                position: absolute;
                left: 0;
                /* height: 20px !important; */
                right: 0;
                /* bottom: 0; */
                margin: auto;
                display: none;
            }

            .show {
                display: inherit;
            }

            .indexTopRightTextDivV3 {
                width: auto;
                height: 89px;
                //width: 200px;
                //height: 200px;
                float: right;
                margin-top: 14px;
                //margin-top: 204px;
                z-index: -3;
                //margin-left: 25px;
                //background: url(../../../../img/bgV3/wznhjc0616.png) no-repeat;
                //background: url(../../../../img/bgV3/energy41.png) no-repeat;
                //background-size: 80px 80px;
                p {
                    font-size: 14px;
                    font-family: MicrosoftYaHeiLight;
                    font-weight: 300;
                    color: #aaceff;
                    opacity: 0.6;
                    //background: url(../../../../img/bgV3/energy0619.png) no-repeat;
                }

                b {
                    width: 100%;
                    float: left;
                    text-align: left;
                    margin-bottom: 7px;

                    div {
                        font-size: 24px;
                        font-family: DINPro-Medium;
                        font-weight: 500;
                        color: #ffffff;
                    }
                }

            }

            .exception {
                p {
                    margin-bottom: 5px;
                }

                .b {
                    font-size: 12px;
                    font-family: PingFang-SC-Bold;
                    font-weight: bold;
                    color: rgba(60, 65, 80, 1);
                    font-weight: normal;
                    width: 100%;
                    margin-top: 1px;

                    span {
                        float: left;
                        line-height: 24px;
                    }

                    div {
                        text-align: left;

                        font-size: 24px;
                        font-family: 'PingFang-SC-Bold';
                        font-weight: bold;
                        color: #5b6b73;
                        line-height: 24px;
                    }
                }
            }

            .border {
                float: left;
                width: 1px;
                height: 34px;
                background: rgba(235, 236, 240, 1);
                position: absolute;
                right: -35px;
                top: 15px;
            }
        }

        li:last-of-type {
            margin-right: 0;
        }
    }
}