.zEnergy {
  margin: 20px 0;
  float: left;
  width: 100%;

  .energyDiv_s {
    float: left;
    width: 600px;
    height: 355px;
    background: #ffffff;
    position: relative;

    .summary {
      position: absolute;
      top: 35%;
      width: 100%;
      left: 6%;

      .daily-total-bold {
        font-weight: bold;
      }
    }
  }

  .energyDiv_b {
    float: left;
    width: 1240px;
    height: 355px;
    margin-left: 20px;
    background: #ffffff;

    .energyButton {
      margin-top: 110px;

      label {
        margin-bottom: 10px;
        border-radius: 4px;
        //border: 1px solid ;
      }
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      //display: none;
    }

  }

  .energyTop,
  .energyBottom {
    // width: 100%;
    float: left;
  }

  .energyTop {
    margin-bottom: 20px;
  }


}

.ant-radio-button-wrapper {
  border-left: 1px solid #d9d9d9;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  content: '';
  display: block;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: transparent;
}