.ScreenVersionIndexV3 {
    .indexBottom2V3 {
        //position: relative;
        position: absolute;
        top: 340px;
        //left:20px;
        width: 480px;
        right: 43px;
        #ReactEcharts {
            margin-top: 25px;
            float: left;
        }

        .legend {
            position: absolute;
            right: 0;
            top: 0;
            height: 75px;
            bottom: 0;
            margin: auto;

            p {
                float: left;
                font-size: 26px;
                font-family: DINPro-Medium;
                font-weight: 500;
                color: rgba(254, 254, 255, 1);
            }

            ul {
                float: left;
                width: 150px;
                li {
                    float: left;
                    width: 100%;
                    margin-bottom: 10px;
                    i {
                        width: 12px;
                        height: 12px;
                        float: left;
                        border-radius: 50%;
                    }

                    span {
                        float: left;
                        font-size: 12px;
                        font-family: PingFangSC-Thin;
                        font-weight: 100;
                        color: #fefeff;
                        line-height: 15px;
                        margin-left: 10px;
                    }
                }
            }
        }

        .indexBottom2Bottom {
            position: absolute;
            //left: 10px;
            left: -4px;
            bottom: 15px;
            margin: auto;
            //width: 100%;

            top: 220px;
            width: 500px;

            div {
                font-size: 12px;
                font-family: 'PingFang-SC-Medium';
                font-weight: 500;
                //color: #8c93a8;
                color: #459dfd;
                line-height: 20px;
                text-align: left;
            }

        }
    }
}