.department {
    float: left;
    margin-top: 20px;

    .departmentLeft {
        float: left;
        width: 280px;
        height: 750px;
        background: rgba(255, 255, 255, 1);

        .departmentLeftTop {
            float: left;
            width: 240px;
            margin: 20px;
            padding-bottom: 35px;
            border-bottom: 1px solid #EBECF0;

            .select {
                float: left;
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .departmentLeftBottom {
            float: left;
        }
        .equipmentLeftTit {
            width: 100%;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
            color: #8b93a7;
            line-height: 34px;
            border-bottom: 1px solid #e8e8e8;
            margin-top: 5px;
            text-align: left;
            padding: 0 30px;
            font-weight: 600;
        }

        .ant-tree {
            padding: 0px 10px;

            li {
                float: left;
                width: 100%;

                span {
                    float: left;
                }
            }
        }

        .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
            background-color: #6188e4;
            color: #fff;
        }
    }

    .departmentRight {
        float: left;
        margin-left: 20px;

        .departmentRightTop {
            background-color: #ffffff;
        }

        .departmentRightTop,
        .departmentRightBottom {
            width: 1560px;
            height: 355px;

            .departmentRightBottom1,
            .departmentRightBottom2,
            .departmentRightBottom3 {
                float: left;
                width: 32%;
                margin-left: 30px;
                background: #ffffff;
                height: 370px;

            }

            .departmentRightBottom3 {
                width: 600px;
            }

            .departmentRightBottom1 {
                margin: 0;
                width: 400px;

                ul {
                    padding: 20px;

                    li {
                        margin-bottom: 10px;
                        width: 100%;
                        float: left;

                        span {
                            float: left;
                            font-size: 14px;
                            font-family: PingFang-SC-Regular;
                            font-weight: 400;
                            color: #3c4150;
                            line-height: 30px;
                            text-align: left;
                            width: 33.333333333333333%;

                            b {
                                margin-left: 10px;
                            }

                            i {
                                margin-left: 10px;
                            }

                            .colorR {
                                color: #ff2448
                            }

                            .colorG {
                                color: #33cf86
                            }
                        }

                        span:last-of-type {
                            float: right;
                        }
                    }

                    .between {
                        margin-top: 20px;

                    }
                }
            }

        }

        .departmentRightTop {
            margin-bottom: 20px;
        }
    }
}