.analysis_right_botton {
    width: 100%;
    height: 355px;
    background-color: #ffffff;
    margin-top: 20px;

    .analysis_right_botton_left {
        float: left;
        width: 480px;
        margin-left: 39px;
        margin-top: 49px;

        div {
            // float: left;
            // width: 100%;

            p {
                float: left;
                width: 100%;

                span {
                    float: left;
                }

                span:last-of-type {
                    // float: right;
                }
            }

        }


        .analysis_right_botton_left_top {
            .ant-row {
                margin-bottom: 15px;

                div {
                    font-size: 14px;
                    font-family: PingFang-SC-Regular;
                    font-weight: 400;
                    color: rgba(60, 65, 80, 1);
                    line-height: 30px;
                    text-align: left;

                    b {
                        font-size: 14px;
                        font-family: PingFang-SC-Bold;
                        font-weight: bold;
                        color: rgba(60, 65, 80, 1);
                        line-height: 30px;
                    }

                    i {
                        font-size: 14px;
                        font-family: PingFang-SC-Bold;
                        font-weight: bold;
                        color: rgba(51, 207, 134, 1);
                        line-height: 30px;
                    }
                }

                div:last-of-type {
                    // text-align: right;
                }
            }
        }

        .analysis_right_botton_left_bottom {
            border-top: 1px solid #E7E9EF;
            padding-top: 25px;

            .ant-row {
                margin-bottom: 25px;

                div {
                    font-size: 14px;
                    font-family: PingFang-SC-Regular;
                    font-weight: 400;
                    color: rgba(60, 65, 80, 1);
                    line-height: 30px;
                    text-align: left;

                    i {
                        font-size: 20px;
                        font-family: PingFang-SC-Bold;
                        font-weight: bold;
                        color: rgba(51, 207, 134, 1);
                        line-height: 30px;
                    }

                    b {
                        font-size: 16px;
                        font-family: PingFang-SC-Bold;
                        font-weight: normal;
                        color: rgba(60, 65, 80, 1);
                        line-height: 30px;
                    }
                }

                div:last-of-type {
                    // text-align: right;
                }
            }



        }
    }

    .analysis_right_botton_right {
        float: right;
        width: 996px;

    }
}