.nav {
    width: 100%;
    height: 60px;
    //background: rgba(97, 136, 228, 0.2);
    background: rgba(97, 136, 228, 1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

    ul {
        float: left;
        margin: 0;

        li {
            float: left;

            // width: 64px;
            a {
                padding: 13px 30px;
                float: left;

                span {

                    height: 16px;
                    font-size: 16px;
                    font-family: 'STHeitiSC-Medium';
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    line-height: 30px;
                }

                i {
                    float: left;
                    // margin-top: 15px;

                    img {
                        float: left;
                        width: 30px;
                        height: 30px;

                    }
                }
            }

            a:hover {
                text-decoration: none;
            }
        }
    }

    &>div {
        float: right;
        // margin-right: 10px;
        padding: 12px 30px;

        img {
            width: 30px;
            height: 30px;
            float: left;
            margin-top: 3px;

        }

        .userHead {
            border-radius: 50%;
            overflow: hidden;
            background-color: #F5F6FA;
        }

        div {
            float: right;

            i {
                float: left;
            }

            span {
                float: left;
                margin-left: 10px;
                font-size: 16px;
                font-family: PingFang-SC-Medium;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                line-height: 36px;
            }
        }

        .clock {
            float: left;
            margin-left: 10px;
            font-size: 18px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
            color: white;
            line-height: 36px;
            margin-right: 20px;
        }
    }

    .active {
        background-color: #577ACC;
        border-bottom: 4px solid #4561A3;
    }
}