.map-bg {
    width: 100%;
    height: 100%;
    //margin-left: 16px; // background: url(/app/img/map.jpg) center center no-repeat;
    background-size: cover;
    position: relative; // width: 1920px;
    overflow: hidden;
    background:#333;
    #map {
        position: relative;
  z-index: 1;
        #popup {
            position: absolute;
        }
    }
}

.buildingpoint {
    position: absolute; // border: 10px;
    height: 20px;
    width: 20px; // border-color: red;
    transition: all 200ms;
}

.buildingpointI {
    height: 20px;
    width: 20px;
    display: inline-block;
    transition: all 500ms;
    background: url(/app/img/map/pointing.png) center center no-repeat;
    background-size: 17px 20px;
    position: absolute;
    z-index: 1;
}

.buildingpoint:hover {
    .buildingpointI {
        background: url(/app/img/map/pointing.gif) center center no-repeat;
        background-size: 20px 20px;
        transform: scale(1.5);
    }
}

// .building01 {
//     top: 26%;
//     left: 35%;
// }
// .building02 {
//     top: 39%;
//     left: 43%;
// }
// .building03 {
//     top: 46%;
//     left: 51%;
// }
.ml33 {
    margin-left: 33px;
}

.ml45 {
    margin-left: 45px;
}

.ml19 {
    margin-left: 19px;
}

.devicemodal {
    position: absolute;
    left: -270px;
    top: -220px;
    z-index: 9;
    width: 550px;
    height: 220px;
    background: rgba(0, 0, 0, 0.5);
    transition: all 500ms;
    color: #ffffff;
}

.devicemodalF {
    position: absolute;
    left: -270px;
    top: 20px;
    z-index: 9;
    width: 550px;
    height: 220px;
    background: rgba(0, 0, 0, 0.5);
    transition: all 500ms;
    color: #ffffff;
}

.modalhead {
    padding: 5px 10px;
    background: rgba(0, 0, 0, 0.6)
}

.text-center {
    text-align: center;
}

.bodytop {
    padding: 15px;
    padding-left: 70px;
}

.bodymiddle {
    padding: 15px;
    padding-left: 70px;
}

.bodybottom {
    padding: 15px;
    padding-left: 70px;
}

.chart {
    top: 7%;
    left: 5%;
    z-index: 999;
    position: relative;
    width: 90%;
}

.usefontsize {
    font-size: 24px;
}

.panel-heading .tabCheckOut {
    border-bottom: #3e8ae5 solid 4px;
    color: #ffffff;
}

.wid50 {
    width: 45px;
    display: block;
    float: left;
    text-align: center;
    color: #7a7a7a;
    border-bottom: transparent solid 4px;
}

.hov:hover {
    border-bottom: #3e8ae5 solid 4px;
    color: #ffffff;
    cursor: pointer;
}

.mapContainer {
    width: 100%;
    height: 100%;
    z-index: 9;
    position: relative;
}

#map .ol-zoom.ol-unselectable.ol-control {
    display: none;
}

//#map {
//    position: absolute;
//    /* height: 100%; */
//    /* width: 100%; */
//    top: 0px;
//    bottom: 0;
//    left: 0;
//    right: 0;
//}


.view-content-fullscreen.mt10 {
    margin-top: 0 !important;
}

.popover {
    width: max-content;
}
////百度地图使用的地图样式
//html,body{
//    margin:0;
//    width:100%;
//    height:100%;
//    background:#333;
//}
#map{
    width:50%;
    height:100%;
    margin:0;
    width:100%;
    height:100%;
    background:#333;
    //background-color: #2DFAD8;
    background-color: #000202;
}
#panel {
    position: absolute;
    height:100%;
    top:30px;
    left:10px;
    z-index: 999;
    color: #fff;
}


