.device {
    .ant-row {
        margin: 10px;

        .deviceContainer {
            background-color: #ffffff;
            height: 200px;
            padding: 10px;

            .icon {
                float: left;
                height: 100%;
                width: 40%;
                font-size: 100px;
                line-height: 200px;
            }

            div {
                float: right;
                width: 50%;
                height: 100%;

                .echarts-for-react {
                    width: 100%;
                    // height: 150px;
                    float: left;
                }

                ul {
                    float: left;
                    width: 100%;

                    li {
                        float: left;
                        width: 25%;
                    }
                }
            }

        }
    }

}