.chartsContainerRight {
    position: absolute;
    top: 0; // left: -700px;
    bottom: 0;
    width: 700px;
    // height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    transition: all 800ms;
    padding: 0;
    margin: 0;
    // z-index: 99999;

    li {
        list-style: none;
        height: 36%;
        // width: 98%; // float: left;
        border-bottom: rgba(78, 69, 69, 0.83) solid 1px;
        margin: 0 auto;
        padding: 10px 0;
        color: #fff;
        position: relative;



        .activeR {
            background-color: #b59e1c;
            //float: left;
            color: #736a55;
        }

        .hoverR {
            cursor: pointer;
            padding: 6px 9px;
            border-radius: 0px 2px 2px 0px;
            border-left: 1px solid #747579;
            border-right: 1px solid #747579;
            border-top: 1px solid #747579;
            border-bottom: 1px solid #747579;
            font-family: 'PingFang-SC-Bold';
            color: #5b6b73;
        }



        .border {
            width: 1px;
            position: absolute;
            top: 20px;
            bottom: 20px;
            margin: 0 auto;
            background-color: rgba(193, 193, 193, 0.52);
        }

        .echarts-for-react {
            height: 100%;
            width: 100%;
            padding: 0 10px;
        }

        .charts50 {
            width: 50%;
            height: 100%;
            display: inline-block;

            .smallCharts {
                width: 50%;
                height: 50%;
                display: inline-block;
                // margin-top: 5px;

                div {
                    float: left;
                    width: 100%;

                    // margin-top: -40px;
                    p {
                        text-align: center;
                        float: left;
                        width: 100%;
                        margin: 0;
                        line-height: normal;
                    }
                }
            }

            .charts100 {
                width: 100%;
                float: left;
                height: 75%;
                display: inline-block;
            }

            .charts60 {
                width: 100%;
                height: 60%;
                display: inline-block;
                float: left;
            }

            div {
                width: 45%;
                float: left;

                p {
                    margin: 0;
                    text-align: inherit
                }
            }

            div:last-of-type {
                float: right;
            }
        }

        .charts100 {
            width: 100%;
            height: 90%;
            display: inline-block;
        }

        p {
            font-size: 14px;
            color: #fff;
            margin: 0;
            margin-top: 3px;
            float: left;
            width: 100%;

            span {
                float: right;
                margin-right: 5px;

                b {
                    font-weight: normal;
                    margin-left: 5px;
                    padding: 3px 5px;
                    border-radius: 5px;
                }

                b:hover {
                    cursor: pointer;
                }

                .check {
                    background-color: rgba(255, 255, 255, 0.5); // color: #fff;
                }
            }
        }
    }

    // li:nth-of-type(2){
    //     height: 60%;
    // }
    li:last-of-type {
        // height: 66.666666666666%;
        border: 0 none;
    }
}



.chartsOpenRight {
    //left: 0px;
    left: calc(100% - 700px);

    left:-webkit-calc(100% - 700px);

    left:-moz-calc(100% - 700px);;
}

.chartsCloseRight {
    //left: -700px;
    left: 100%;
}




.indexBottom2Right {
    position: relative;

    #ReactEcharts {
        margin-top: 25px;
        float: left;
    }

    .legend {
        position: absolute;
        right: 0;
        top: 75px;
        height: 75px;
        bottom: 0;
        margin: auto;

        p {
            float: left;
            font-size: 26px;
            font-family: DINPro-Medium;
            font-weight: 500;
            color: #5b6b73;
        }

        ul {
            float: left;
            width: 175px;
            li {
                float: left;
                width: 100%;
                margin-bottom: 10px;
                i {
                    width: 12px;
                    height: 12px;
                    float: left;
                    border-radius: 50%;
                }

                span {
                    float: left;
                    font-size: 12px;
                    font-family: PingFangSC-Thin;
                    font-weight: 100;
                    color: #5b6b73;
                    line-height: 15px;
                    margin-left: 10px;
                }
            }
        }
    }

    .indexBottom2Bottom {
        position: absolute;
        left: 10px;
        bottom: 5px;
        margin: auto;
        width: 100%;

        div {
            font-size: 12px;
            font-family: 'PingFang-SC-Medium';
            font-weight: 500;
            color: #8c93a8;
            line-height: 20px;
            text-align: left;
        }

    }
}

.mapTableRightType {
    .ant-table-fixed-header  .ant-table-content  .ant-table-scroll  .ant-table-body{
        //background-color: #96957b;
        background-color: transparent;
        color: #e4f1ec;
    }

    .ant-table-thead .tr .th, .ant-table-tbody .tr .td{
        //background-color: #96957b;
        background-color: transparent;
        color: #e4f1ec;

    }
    .ant-table-body {
        //background-color: #96957b;
        background-color: transparent;
        color: #e4f1ec;
        .tr .td {
            //background-color: #96957b;
            background-color: transparent;
            color: #e4f1ec;
        }
    }
    .ant-table-header
    {
        //background-color: #3c4150;
        background-color: transparent;
        color: #e4f1ec;
    }
    .ant-table-thead > tr > th {
        //background-color: #857273;
        background-color: transparent;
        color: #e4f1ec;
    }
    .ant-empty-normal .ant-empty-image {
        //background-color: #857273;
        background-color: transparent;
        color: #e4f1ec;
    }
    .ant-table-placeholder {
        //background-color: #96957b;
        background-color: transparent;
        color: #e4f1ec;
    }
    //.ant-table-thead > tr > th .ant-table-column-sorters:before {
    //    background-color: #857273;
    //}



}


.echartsDiv {
    float:left;
    position: relative;
    margin-left: 6%;
}
