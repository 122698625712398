#mapPopup {
    // width: 100px;
    // height: 30px;
    position: relative;
    background: #fff;
    z-index: 999;
    padding: 9px 14px;
    border-radius: 4px;

    .arrow {
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -11px;
        border-color: transparent;
        border-style: solid;
        border-top-color: #fff;
        border-bottom-width: 0;
        border-width: 10px;
        display: block;
        width: 0;
        height: 0;

    }
}