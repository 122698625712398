.indexTopLeft {
    float: left;
    width: 1480px;
    height: 110px;
    background: rgba(255, 255, 255, 1);
    margin: 0;
    margin-left: 20px;

    li {
        float: left;
        margin-left: 20px;
        margin-top: 23px;
        margin-right: 10px;
        position: relative;
        width: 260px;
        padding: 0 10px 0 0;

        p {
            font-size: 14px;
            font-family: 'PingFang-SC-Medium';
            font-weight: 500;
            color: rgba(140, 147, 168, 1);
            line-height: 20px;
            text-align: left;

            span {
                float: right;
                font-size: 16px;
                font-family: PingFang-SC-Bold;
                font-weight: bold;
                line-height: 22px;

                i {
                    float: right;

                    img {
                        width: 30px;
                        height: 30px;
                        margin-top: -5px;
                        transform: rotate(180deg)
                    }
                }
            }

            .up {
                color: #FF2944;
            }

            .down {
                color: #33CF86;
            }
        }

        b {
            float: left;
            width: 100%;
            height: 37px;
            font-size: 26px;
            font-family: 'PingFang-SC-Bold';
            font-weight: bold;
            color: #5b6b73;
            line-height: 37px;
            text-align: left;
        }

        .border {
            float: left;
            width: 1px;
            height: 34px;
            background: rgba(235, 236, 240, 1);
            position: absolute;
            right: 0;
            top: 15px;
        }
    }

    li:last-of-type {
        padding-right: 0;
    }
}