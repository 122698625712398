.ScreenVersionIndex {

    .indexMidLeftForWeather {
        width: 100%;
        height: 422px;
        background: transparent;
        float: left;
        margin-left: 0;
        position: relative;

        .indexMidLeftHeadForWeather {
            float: left;
            width: 100%;
            padding: 20px 16px;

            .border4Container {
                float: left;
                width: 17px;
                height: 17px;
                position: relative;

                .round {
                    float: left;
                    width: 5px;
                    height: 5px;
                    background: rgba(254, 181, 47, 1);
                    opacity: 0.8;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }


            span {
                float: left;
                line-height: 17px;
                margin-left: 10px;

                font-size: 14px;
                font-family: PingFangSC-Thin;
                font-weight: 100;
                color: rgba(255, 255, 255, 1);
            }

            div {
                float: right;
                i {
                    float: left;
                    border-radius: 0px;
                    width: 26px;
                    height: 26px;
                    background: rgba(14, 29, 62, 1);
                    border: 0 none;
                    opacity: 0.3;
                    margin-left: 5px;

                    img {
                        width: 26px;
                        height: 26px;
                    }
                }
                .active {
                    background: rgba(255, 255, 255, 1);
                    opacity: 0.2;
                }
            }
        }

        .echarts-for-react {
            float: left;
        }
    }

}
