.border2Container {
    position: relative;
    float: left;

    .border2 {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10px;
        border-top: 1px solid #7aa2fe;
        border-bottom: 1px solid #7aa2fe;
        opacity: 0.2;
    }

    .border2Left {
        border-left: 1px solid #7aa2fe;
        left: 0;
    }

    .border2Right {
        border-right: 1px solid #7aa2fe;
        right: 0;
    }
}