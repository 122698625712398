.indexBottom {
    // width: 100%;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    float: left;

    .indexBottom1,
    .indexBottom2,
    .indexBottom3,
    .indexBottom4 {
        width: 445px;
        height: 315px;
        background: rgba(255, 255, 255, 1);
        padding: 15px;
        float: left;

        .indexBottomHead {
            float: left;
            width: 100%;
            position: relative;
            z-index: 999;

            span {
                float: left;
                font-size: 14px;
                font-family: PingFang-SC-Bold;
                font-weight: bold;
                color: rgba(140, 147, 168, 1);
                line-height: 20px
            }

            div {
                float: right;

                span {
                    margin-right: 10px;
                }

                i {
                    float: left;
                    padding: 6px 9px;
                    // background: rgba(255, 255, 255, 1);
                    border-radius: 0px 2px 2px 0px;
                    border-left: 1px solid rgba(227, 230, 237, 1);
                    border-top: 1px solid rgba(227, 230, 237, 1);
                    border-bottom: 1px solid rgba(227, 230, 237, 1);
                    font-family: 'PingFang-SC-Bold';
                    color: #5b6b73;
                }

                i:last-of-type {
                    border-right: 1px solid rgba(227, 230, 237, 1);
                }
            }

        }

        // .indexBottomHead4 {


        //     div {
        //         i {
        //             padding: 6px 36px;

        //         }

        //     }
        // }

        .echarts-for-react {
            float: left;
            margin-top: 25px;
        }
    }

    .indexBottom2 {
        .echarts-for-react {
            float: left;
            margin-top: 0px;
        }
    }

    .indexBottom1,
    .indexBottom2,
    .indexBottom3 {
        margin-right: 20px;
    }


}