.indexMidRight {
    width: 600px;
    height: 345px;

    .indexMidRightHead {
        div {
            i {
                width: auto;
                height: auto;
                padding: 6px 22px;
                border-radius: 0px 2px 2px 0px;
                border: 1px solid rgba(227, 230, 237, 1);
                font-size: 14px;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                color: rgba(60, 65, 80, 1);
                line-height: 20px;
            }
        }

    }

}