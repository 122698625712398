@charset "UTF-8";
body {
  margin: 0;
  padding: 0;
  font-family: 'MicrosoftYaHei'-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F6FA;
  min-width: 1900px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

ul li {
  list-style: none; }

#root {
  height: 100%;
  width: 100%; }

* {
  box-sizing: border-box;
  text-decoration: none;
  margin: 0;
  padding: 0;
  font-style: normal; }

img {
  width: 30px;
  height: 30px; }

::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0); }

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(91, 107, 115, 0.5);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0); }
